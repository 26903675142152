$(function() {
	$('.js-popup').click(function (e) {
		e.preventDefault();
		$('body').addClass('o-hidden');
		$('#popup').addClass('popup_active');
	});

	$('.js-popup-close').click(function (e) {
		e.preventDefault();
		$('body').removeClass('o-hidden');
		$('.popup').removeClass('popup_active');
		$('form').trigger('reset');
	});

	$('form').submit(function (e) {
		e.preventDefault();
		$('#popup').removeClass('popup_active');
		$('#popup-thanks').addClass('popup_active');
	});
})